import XHR from '../xhr.js';
const req = new XHR({
  baseURL: '/admin/v1/admin'
});

 /**
  * 分页查询
  * @return void
  */
export const adminPage = (data) => req.post('/page', data );

 /**
  * 查询详情
  * @return void
  */
export const adminDetail = (data) => req.post('/detail',{params:data});

 /**
  * 创建admin
  * @return void
  */
export const adminCreate = (data) => req.post('/create', data);

 /**
  * 更新admin
  * @return void
  */
export const adminUpdate = (data) => req.post('/update',data);


/**
 * 删除admin
 * @return void
 */
export const adminDelete = (data) => req.post('/delete',data);
