import axios from 'axios';
// import qs from 'qs';
// import ErrorCode from './apis/errorCode';
import { getSession } from '@/assets/utils.js';

class XRH {
  constructor (config) {
    this.config = {
      baseURL: '/v1/',
      headers: {
        "ADMIN-TOKEN": getSession('yxtoken'),
        'Content-Type': 'application/json; charset=UTF-8'
      }
    };
    // 初始化 请求实例
    this.config = Object.assign(this.config, config);
    this.$request = axios.create(this.config);
  }

  get (url, data, headers = {}) {
    return this.request(url, data, 'GET', headers);
  }

  post (url, data, headers = {}) {
    return this.request(url, data, 'POST', headers);
  }

  request (url, data, method = 'GET', headers) {
    if (!getSession('yxtoken') && !url.includes('login')) {
      window.location.href = location.origin + '#/login';
      return Promise.reject({ msg: '登录过期了哦，请重新登录' });
    }
    // 格式化参数
    if (typeof data === 'string') {
      method = data;
      data = {};
    }
    const config = { url, method, headers };
    // 区分 post get
    if (method === 'GET') {
      config.params = data;
    } else {
      if (data.params) {
        config.params = data.params;
        config.data = data.data;
      } else {
        config.data = data;
      }
    }
    // 发起请求
    return this.$request(config).then(({ data }) => {
      if (typeof data === 'string') {
        return Promise.reject({ msg: '出现异常了，稍后再试试' });
      }
      if (headers.allRes) {
        return data;
      } else {
        if (data && data.code == 0) {
          const resulte = {
            msg: data.msg,
            data: data.data,
            code: parseInt(data.code || 0)
          };
          return resulte;
        } else {
          // 没有错误信息时，取错误码对应错误提示
          // if (!data.msg) {
          //   data.msg = ErrorCode[data.code];
          // }
          return Promise.reject(data);
        }
      }
    }).catch((error) => {
      if (typeof error === 'string') {
        return Promise.reject({ msg: '出现异常了，稍后再试试' });
      } else {
        return Promise.reject(error);
      }
    });
  }
}

export default XRH;
